.bottombar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	font-size: 1rem;
	color: #fff;
}

.bottombar-close {
	position: absolute;
	top: .75rem;
	right: 1rem;
	color: $text-muted;
	transition: .3s color;
	display: block;
	margin-left: 1rem;

	&:hover {
		color: $text-muted-dark;
	}
}

.bottombar-image {
	position: relative;
	display: block;
	margin: 0 1rem 0 0;

	@include media-breakpoint-up(lg) {
		margin: -176px 1rem -90px -25px;
	}

	img {
		width: 109px;
		display: block;

		@include media-breakpoint-up(lg) {
			width: 218px;
		}
	}
}
