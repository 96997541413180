@import '../tabler-ui/scss/bundle';
@import 'amazon.scss';
@import 'sweet-modal.scss';
@import 'slide-out.scss';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~flatpickr/dist/flatpickr.css";
@import "~mediaelement/src/css/mediaelementplayer.css";
@import "~sweetalert2/src/sweetalert2.scss";
@import "~trumbowyg/dist/ui/trumbowyg.min.css";

.dataTables_wrapper {
  table {
    td {
      padding-left: 0.75em;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}

.radio-width {
  width: 250px;
}

.table-loader {
  margin: 25px auto 25px auto;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.page-object {
  background-color: white;
  padding: 15px;
}

//.fa, .fab, .far, .fe {
//  color: #D592AA !important;
//}

.btn-primary {
  background-color: #006BA6 !important;
  border-color: #006BA6 !important;
}
