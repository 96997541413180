.map,
.chart {
	position: relative;
	padding-top: (9/16)*100%;
}

.map-square,
.chart-square {
	padding-top: 100%;
}

.map-content,
.chart-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.map-header {
	margin-top: -1.5rem;
	margin-bottom: 1.5rem;
	height: 15rem;
	position: relative;

	//todo
	margin-bottom: -1.5rem;

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 10rem;
		background: linear-gradient(to bottom, rgba($body-bg, 0) 5%, $body-bg 95%);
		pointer-events: none;
	}
}

.map-header-layer {
	height: 100%;
}

.map-static {
	height: 120px;
	width: 100%;
	max-width: 640px;
	background-position: center center;
	background-size: 640px 120px;
}
