.sweet-title > h2 {
  line-height: 64px;
}

.sweet-buttons > button {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}