$browsers: (
	'android-browser', 'aol-explorer', 'blackberry', 'camino', 'chrome', 'chromium', 'dolphin', 'edge',
	'firefox', 'ie', 'maxthon', 'mozilla', 'netscape', 'opera', 'safari', 'sleipnir', 'uc-browser', 'vivaldi'
);

.browser {
	width: 1.25rem;
	height: 1.25rem;
	display: inline-block;
	background: no-repeat center/100% 100%;
	vertical-align: bottom;
	font-style: normal;
}

@each $browser in $browsers {
	.browser-#{$browser} {
		background-image: url('../../images/browsers/' + $browser + '.svg');
	}
}
